<template class="b-card">
  <b-card>

    <!-- Loader -->
    <b-row v-if="isLoading" class="py-5">
      <loading
        :active="true" 
        :is-full-page="false"
        :color="colors.primary"
      />
    </b-row>

    <b-row v-else class="mx-lg-4">
      <!-- Edit and Delete button -->
      <b-col md="12" v-if="role !== 'supervisor' && isOnline" class="d-flex justify-content-end">
        <EditButton @clicked="router.push({ name: 'habit-meeting-communications-edit', params: { id:  viewCommunication._id } })"/>
        <DeleteButton v-b-modal.deleteCom />
        <b-modal
          id="deleteCom"
          centered
          no-close-on-backdrop
          modal-class="modal-danger"
          ok-variant="danger"
          cancel-variant="outline-secondary"
          :title="$t('message.confirm_action')"
          :ok-title="$t('message.delete')"
          :cancel-title="$t('message.cancel')"
          @ok="deleteCommunication(null)"
        > {{ $t('message.confirm_delete_communication') }}
        </b-modal>
      </b-col>

      <!-- Title -->
      <b-col md="12" class="mb-2">
        <h5 class="text-primary" style="font-weight: bold">{{ $t('message.tableHeader.title') }}</h5>
        <span>{{ viewCommunication.title  }}</span>
      </b-col>

      <b-col md="5" class="mb-2">
        <!-- Image -->
        <b-col md="12" class="mx-0 px-0 mb-2" >
          <h6 class="text-primary" style="font-weight: bold">{{ $t('message.tableHeader.img') }}</h6>
          <div v-if="viewCommunication.imageKey" @click="openImage(viewCommunication.imageKey)" class="image-container" style="border: 1.5px solid #ccc;">
            <img :src="viewCommunication.src" alt="img" class="img-preview">
            <span class="hover-text">
              {{ $t('message.tableHeader.fullSize') }}
            </span>
          </div>
          <div v-else class="image-container">
            <img :src="require('@/assets/images/elements/no-image.jpg')" alt="img" class="img-preview">
            <span class="no-image">{{ $t('happiness.no_image') }}</span>
          </div>
        </b-col>

        <b-row class="mr-2">

          <!-- Date from -->
          <b-col md="6" class="mb-2">
            <h6 class="text-primary" style="font-weight: bold;">
              {{ $t('message.tableHeader.dateFrom') }}:
            </h6>
            <span>{{ viewCommunication.dateFrom }}</span>
          </b-col>
    
          <!-- Date to -->
          <b-col md="6">
            <h6 class="text-primary" style="font-weight: bold;">
              {{ $t('message.tableHeader.dateTo') }}:
            </h6>
            <span>{{ viewCommunication.dateTo }}</span>
          </b-col>

          <!-- Locations -->
          <b-col md="12">
            <h6 class="text-primary" style="font-weight: bold;">
              {{ $t('message.tableHeader.locations') }}:
            </h6>
            <ul class="pl-1">
              <li v-for="location in viewCommunication.locations" :key="location.location">
                {{ location.location }}
              </li>
            </ul>
          </b-col>

        </b-row>
      </b-col>

      <!-- Detail -->
      <b-col md="7" class="mb-2" style="border-left: 1px solid #ccc;">
        <h6 class="text-primary" style="font-weight: bold;">
          {{ $t('message.tableHeader.detail') }}:
        </h6>
        <div class="text-justify">
          <p v-html="viewCommunication.detail"></p>
        </div>
      </b-col> 
    </b-row>

  </b-card>
</template>

    
<script>
import useCommunications from './useCommunications'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { onMounted } from '@vue/composition-api'
import {BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import { colors } from '@/constants'

export default {
  components: {
    Loading,
    BModal,
    EditButton,
    DeleteButton
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {

    const {
      viewCommunication,
      isLoading,
      openImage,
      role,
      isOnline,
      router,
      getOneCommunication,
      deleteCommunication
    } = useCommunications()

    onMounted(() => {
        getOneCommunication('view')
    })

    return {
      viewCommunication,
      isLoading,
      openImage,
      role,
      isOnline,
      router,
      deleteCommunication,
      colors
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.hover-text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.image-container:hover .hover-text {
  opacity: 1;
}

.img-preview {
  max-width: 200px;
  max-height: 200px;
  margin-right: 10px;
  border: none;
}

.no-image {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.3s;
  white-space: nowrap;
  font-size: .9rem;
}
</style>